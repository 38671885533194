export const styles = {
    list: {
        marginLeft: "16px",
        marginRight: "16px",
    },
    actionList: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: "16px"
    }
};