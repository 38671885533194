import * as CompanyEmailValidator from "company-email-validator"

const useInviteTeamMemberLogic = () => {

  // Custom email validation function
  const validateCompanyEmail = (rule, value, callback) => {
    if (value && !CompanyEmailValidator.isCompanyEmail(value)) {
      callback("Please enter a company email address!");
    } else {
      callback();
    }
  };

  return {
    validateCompanyEmail,
  }
}

export default useInviteTeamMemberLogic