import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import TOS from "./pages/GeneralInformation/TOS/TOS";

import "./App.less";
import { Home } from "./pages/Home/Home";
import SearchResults from "./pages/SearchResults/SearchResults";
import AccommodationDetails from "./pages/AccommodationDetails/AccommodationDetails";
import { BOOKING_INFO, HOTEL_DETAIL, PROFILE, ROOMS_LOCATION } from "constants/RouteNames";
import CheckoutPage from "./pages/PersonalDetails/PersonalDetails";
import { AllBookings } from "./pages/Booking/AllBookings";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { FaWhatsapp } from "react-icons/fa";
import PaymentCheckout from "./pages/PaymentCheckout/PaymentCheckout";
import usePageTracking from "hooks/usePageTracking";
// import AcceptTeamInvitation from "./pages/AcceptTeamInvitation";
import ProtectedRoute from "./helpers/ProtectedRouteHelper";
import { ResetPassword, FAQGeneral, Esim, EsimTos, AboutUs, ContactUsForm, PrivacyPolicy, MyAccount } from "./pages";
import Error from "./pages/Error/Error";
import Profile from "./pages/Auth/Profile/Profile";
import BookingInfo from "./pages/Booking/BookingInfo/BookingInfo";
import BookingOngoing from "./pages/Booking/BookingOngoing/BookingOngoing";
import { SingleBooking } from "./pages/Booking/SingleBooking/SingleBooking";
import Transaction from "./components/Transaction/Transaction";

import { CurrencyProvider } from "hooks/useCurrencyContext";
import SecretResetPassword from "pages/Auth/SecretResetPassword/SecretResetPassword";
import ResetNewPassword from "pages/Auth/ResetNewPassword/ResetNewPassword";
import ResetNewPasswordSuccess from "pages/Auth/ResetNewPassword/ResetNewPasswordSuccess";
import AcceptTeamInvitation from "pages/B2BIndosat/AcceptTeamInvitation/AcceptTeamInvitation";
import Axios from "axios";
import { getBaseURL } from "constants/Endpoint"

function App() {

  console.log(process.env)

  usePageTracking();

  Axios.defaults.baseURL = getBaseURL();
  // Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL ?? 'https://api.visitorsdeals.com'; 
  const [showButton, setShowButton] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 5000)
  }, []);

  return (
    <>
      <CurrencyProvider>
        <Switch>
          <Route exact path="/yoodo" component={Home} />
          <Route exact path="/indosat" component={Home} />
          <Route exact path="/business" component={Home} />
          <ProtectedRoute exact path="/business-dashboard" component={Home} />
          {/*<Route exact path={ROOMS} component={SearchResults} />*/}
          <Route exact path={ROOMS_LOCATION} component={SearchResults} />
          <Route exact path={PROFILE} component={Profile} />
          <Route exact path="/" component={Home} />
          <Route exact path={HOTEL_DETAIL} component={AccommodationDetails} />
          <Route exact path="/AboutUs" component={AboutUs} />
          <Route exact path="/ContactUs" component={ContactUsForm} />
          <Route exact path="/termsandconditions" component={TOS} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route exact path={BOOKING_INFO} component={CheckoutPage} />
          <Route exact path="/transactions" component={Transaction} />
          <Route exact path="/Myaccount" component={MyAccount} />
          <Route exact path="/allbooking" component={AllBookings} />

          <Route exact path="/payment/checkout" component={PaymentCheckout} />
          <Route exact path="/payment/booking" component={BookingOngoing} />
          <Route exact path="/payment/success" component={BookingInfo} />
          <Route exact path="/payment/failed" component={BookingInfo} />
          <Route exact path="/booking" component={BookingInfo} />
          {/*<Route exact path="/payment/cancel" component={AllBookings} />*/}

          <Route exact path="/esim" component={Esim} />
          <Route exact path="/esim-tnc" component={EsimTos} />
          <Route exact path="/faq" component={FAQGeneral} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/secret-reset-password" component={SecretResetPassword} />
          <Route exact path="/reset-new-password" component={ResetNewPassword} />
          <Route exact path="/reset-password/success" component={ResetNewPasswordSuccess} />
          <Route exact path="/accept-invitation" component={AcceptTeamInvitation} />


          <Route exact path="/single" component={SingleBooking} />
          <Route component={Error} />
        </Switch>

        {showButton && <div style={{ position: 'fixed', bottom: '85px', right: '25px', top: 'auto', fontSize: '60px' }}>
          <a style={{ color: 'green' }} target="_blank" href="https://wa.me/60128966110">
            <FaWhatsapp />
          </a>
        </div>}
        {process.env.REACT_APP_ENV !== 'local' &&
          <MessengerCustomerChat
            pageId="1740169049605102"
            appId="208307229975285"
          />
        }
      </CurrencyProvider>
    </>
  );
}

export default App;
