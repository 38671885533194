import React from "react";
import Header from "components/Header/Header";

import useSecretResetPasswordStyles from './SecretResetPassword.styles'
import SecretResetPasswordForm from "./SecretResetPasswordForm";

const SecretResetPassword = () => {
    const { styles } = useSecretResetPasswordStyles()

    return (
        <div>
            <Header isDarkFont={true} />

            <SecretResetPasswordForm />
        </div>
    );
};

export default SecretResetPassword;