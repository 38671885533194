import { useState } from 'react';
import Axios from 'axios';
import { message } from 'antd';

const useSecretResetPasswordFormLogic = ({ form }) => {
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const onClickSubmit = (e) => {
        e.preventDefault();
        form.validateFields(async (err, values) => {
            if (!err) {
                setIsLoadingSubmit(true);
                
                try {
                    const { email, newPassword, token } = values;
                    
                    // Make backend call to reset password
                    const res = await Axios.post('/auth/password/secret-reset', { email, newPassword, token });
                    
                    if (res.status === 200) {
                        message.success('Password reset successfully');
                    } else {
                        message.error('Failed to reset password');
                    }
                } catch (error) {
                    message.error('Error occurred while resetting password');
                } finally {
                    setIsLoadingSubmit(false);
                }
            }
        });
    };

    const getButtonActionTitle = () => isLoadingSubmit ? 'Submitting...' : 'Submit';

    const isButtonDisabled = () => isLoadingSubmit;

    return {
        isLoadingSubmit,
        onClickSubmit,
        getButtonActionTitle,
        isButtonDisabled
    };
};

export default useSecretResetPasswordFormLogic;
