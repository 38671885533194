import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Select, Avatar, Button } from "antd";
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Header.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie'
import { useCurrencyContext } from "hooks/useCurrencyContext";

import { useMediaQuery } from "react-responsive";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { loginModalVisibleState, signUpModalVisibleState, temporaryUserState } from "recoil/atoms"
import SignUpModalV2 from "components/Auth/SignUpModalV2/SignUpModalV2";
import LoginModalV2 from "components/Auth/LoginModalV2/LoginModalV2";

const languages = [
  {
    country_code: 'gb',
    code: 'en',
    name: 'EN',
  },
  {
    country_code: 'id',
    code: 'id',
    name: 'ID',
  },
]

const Header = ({
  signupColor,
  containerStyle,
  openLoginModal = false,
  logoColor = null,
  // isMobile = false,
  isDarkFont = false,
  // openSignUp,
  // modalVisible,
  // setmodalVisible,
  // modalType,
  // setmodalType,
}) => {
  const isMobile = useMediaQuery({
    maxWidth: 775
  })

  const location = useLocation()
  const history = useHistory()

  // Popups
  const setIsOpenLoginModal = useSetRecoilState(loginModalVisibleState);
  const setIsOpenSignUpModal = useSetRecoilState(signUpModalVisibleState);

  const { t } = useTranslation()
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = () => languages.find(language => language.code === currentLanguageCode)
  const [lang, setLang] = useState(currentLanguage)


  const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }

  const [user, setuser] = useState(false);

  const [selectLang, setSelectedLang] = useState('EN');


  const [providerConfig, setProviderConfig] = useState({
    status: false,
    name: ''
  });
  const [selectedCurrency, setCurrency] = useCurrencyContext();
  const [actionMenuVisible, setActionMenuVisible] = useState(false);

  const changeCurrency = (val) => {
    localStorage.setItem("currency", val);
    setCurrency(val);
  };

  const openLogin = () => {
    setIsOpenLoginModal(true)
  };
  const openSignUp = () => {
    setIsOpenSignUpModal(true)
  };

  const getDefaultCurrencyByProvider = () => {
    if (providerConfig && providerConfig.name === 'yoodo') {
      return 'MYR'
    }
    else {
      return 'USD'
    }
  }

  useEffect(() => {
    setCurrency(localStorage.getItem("currency") || 'MYR');
  }, []);


  const temporaryUser = useRecoilValue(temporaryUserState)
  useEffect(() => {
    console.log(temporaryUser)
    if (temporaryUser.fullName) {
      setuser(temporaryUser.fullName);
    } else if (localStorage.usertoken && localStorage.userdata) {

      const data = JSON.parse(localStorage.getItem("userdata"));

      setuser(data.first_name + " " + data.last_name);
    }
  }, [temporaryUser]);

  useEffect(() => {
    if (openLoginModal) {
      openLogin();
    }

  }, [openLoginModal]);

  useEffect(() => {
    if (providerConfig?.name !== pConfig?.name) {
      let cfg = {
        status: false,
        name: null,
        loginMsgColor: null
      }

      if (hasProvider) {
        cfg = {
          status: true,
          name: pConfig?.name,
          loginMsgColor: pConfig?.textColor
        }
      }
      setProviderConfig({ ...providerConfig, ...cfg })
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('currency')) {
      changeCurrency(getDefaultCurrencyByProvider());
    }
  }, [providerConfig])

  const signInMenu = (
    <Menu style={{ width: '12rem' }}>
      <Menu.Item>
        <div style={{ color: "#d93b4f", fontWeight: 'bold' }} onClick={openLogin}>
          LOG IN
        </div>
      </Menu.Item>
      <Menu.Item>
        <div style={{ color: "#d93b4f", fontWeight: 'bold' }} onClick={openSignUp}>
          SIGN UP
        </div>
      </Menu.Item>
    </Menu>
  )

  const actionMenu = (
    <Menu style={{ width: '12rem' }} >
      <Menu.Item key="1">
        <div className="header-currency-dropdown" style={{
          color: '#d93b4f', fontWeight: 'bold', marginRight: '10px',
          display: providerConfig.name == 'business' ? 'none' : 'block',
        }}>
          <Select
            value={selectedCurrency}
            onChange={(value) => { changeCurrency(value) }}
          >
            <Select.Option key="MYR">MYR</Select.Option>
            <Select.Option key="USD">USD</Select.Option>
            <Select.Option key="EUR">EUR</Select.Option>
            <Select.Option key="IDR">IDR</Select.Option>
            <Select.Option key="INR">INR</Select.Option>
            <Select.Option key="AED">AED</Select.Option>
            <Select.Option key="PHP">PHP</Select.Option>
            <Select.Option key="BHD">BHD</Select.Option>
            <Select.Option key="SAR">SAR</Select.Option>
            <Select.Option key="OMR">OMR</Select.Option>
            <Select.Option key="QAR">QAR</Select.Option>
          </Select>
        </div>
      </Menu.Item>
      {providerConfig.name === 'indosat' &&
        <Menu.Item key="2">
          <div>
            {languages && languages.map((language, index) => {
              return (
                <>
                  <span key={language.code}
                    onClick={() => {
                      i18next.changeLanguage(language.code)
                      setLang(language)
                    }}
                    className={lang.name == language.name ? 'text-primary' : 'text-dark'}
                    style={{ cursor: 'pointer' }}>{language.name}</span>
                  {index !== languages.length - 1 ? <span style={{ margin: '0px 5px', fontWeight: 'bold', }}>|</span> : <></>}
                </>
              )
            })}
          </div>

        </Menu.Item>
      }
    </Menu>
  )

  const headerFontColor = isDarkFont ? '#000' : '#fff';

  const signInDesktop = (
    <div className="header-signin-container-wrapper">
      <div className="header-currency-dropdown" style={{
        color: '#d93b4f', fontWeight: 'bold', marginRight: '10px',
        display: providerConfig.name == 'business' ? 'none' : 'block',
      }}>
        <Select
          value={selectedCurrency}
          onChange={(value) => { changeCurrency(value) }}
        >
          <Select.Option key="MYR">MYR</Select.Option>
          <Select.Option key="USD">USD</Select.Option>
          <Select.Option key="EUR">EUR</Select.Option>
          <Select.Option key="IDR">IDR</Select.Option>
          <Select.Option key="INR">INR</Select.Option>
          <Select.Option key="AED">AED</Select.Option>
          <Select.Option key="PHP">PHP</Select.Option>
          <Select.Option key="BHD">BHD</Select.Option>
          <Select.Option key="SAR">SAR</Select.Option>
          <Select.Option key="OMR">OMR</Select.Option>
          <Select.Option key="QAR">QAR</Select.Option>
        </Select>
      </div>

      <div>
        {providerConfig.name === 'indosat' &&
          <>
            {languages && languages.map((language, index) => {
              return (
                <>
                  <span key={language.code}
                    onClick={() => {
                      i18next.changeLanguage(language.code)
                      // currentLanguage()
                      setLang(language)
                    }}
                    className={lang.name == language.name ? 'text-primary' : 'text-dark'}
                    style={{ cursor: 'pointer' }}>{language.name}</span>
                  {index !== languages.length - 1 ? <span style={{ margin: '0px 5px', fontWeight: 'bold', }}>|</span> : <></>}
                </>
              )
            })}
          </>
        }
      </div>

      <div className="buttonContainer" style={isMobile ? { marginTop: '-2rem' } : {}}>
        <div
          className="button"
          onClick={openSignUp}
          style={{ color: signupColor ? signupColor : "black", fontWeight: 'bold' }}
        >
          SIGN UP
        </div>
        <div className="button" style={{ color: "#d93b4f", fontWeight: 'bold' }} onClick={openLogin}>
          LOG IN
        </div>
      </div>
    </div>
  )

  const signInMobile = (
    <div className="header-signin-container-wrapper">
      <div>
        <Dropdown trigger={['click']} overlay={signInMenu} placement="bottomLeft">
          <UserOutlined style={{ fontSize: '20px' }} />
        </Dropdown>
      </div>
      <div>
        <Dropdown trigger={['click']} overlay={actionMenu} placement="bottomLeft" visible={actionMenuVisible} onVisibleChange={(val) => setActionMenuVisible(val)}>
          <MenuOutlined style={{ fontSize: '20px' }} />
        </Dropdown>
      </div>
    </div>
  );
  const signIn = isMobile ? signInMobile : signInDesktop;

  const logout = (event) => {
    // event.preventDefault()
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userdata");
    localStorage.removeItem("useremail");
    localStorage.removeItem("is-test-acc");
    setuser(false);

    const currentPath = location.pathname;
    if (currentPath === '/business-dashboard') window.location.href = '/business'
    else window.location.href = '/'
  };

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const menu = (
    <Menu>
      {/*<Menu.Item>*/}
      {/*  <Link to="/transactions">Your Transaction</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.Item disabled>
        Hi, {user}
      </Menu.Item>
      {userData?.isPIC && (
        <Menu.Item>
          <Link to="/business-dashboard">Business Dashboard</Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to="/Myaccount">My account</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/" onClick={logout}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const welcomeTextColor = isDarkFont ? '#000' : isMobile ? '#000' : (window.location.pathname == '/Myaccount' ? '#000' : '#fff');

  const welcomeMobile = (
    <div className="header-signin-container-wrapper">
      <div>
        <Dropdown trigger={['click']} overlay={menu} placement="bottomLeft">
          {/* <div style={{ cursor: "pointer", textWrap: 'nowrap', color: welcomeTextColor, display: 'flex', alignItems:'center'}}>
            Welcome, <b>{user}</b>
          </div> */}
          <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>{typeof user === 'string' ? user?.split(' ')?.map(e => e[0])?.join('') : 'U'}</Avatar>
        </Dropdown>
      </div>
      <div>
        <Dropdown trigger={['click']} overlay={actionMenu} placement="bottomLeft" visible={actionMenuVisible} onVisibleChange={(val) => setActionMenuVisible(val)}>
          <MenuOutlined style={{ fontSize: '20px' }} />
        </Dropdown>
      </div>
    </div>
  );

  const welcomeDesktop = (
    <div className="header-signin-container-wrapper">
      <div className="header-currency-dropdown" style={{
        color: '#d93b4f',
        fontWeight: 'bold',
        marginRight: '10px',
        display: providerConfig.name == 'business' ? 'none' : 'block',
      }}>
        <Select
          value={selectedCurrency}
          onChange={(value) => { changeCurrency(value) }}
        >
          <Select.Option key="MYR">MYR</Select.Option>
          <Select.Option key="USD">USD</Select.Option>
          <Select.Option key="EUR">EUR</Select.Option>
          <Select.Option key="IDR">IDR</Select.Option>
          <Select.Option key="INR">INR</Select.Option>
          <Select.Option key="AED">AED</Select.Option>
          <Select.Option key="PHP">PHP</Select.Option>
          <Select.Option key="BHD">BHD</Select.Option>
          <Select.Option key="SAR">SAR</Select.Option>
          <Select.Option key="OMR">OMR</Select.Option>
          <Select.Option key="QAR">QAR</Select.Option>
        </Select>
      </div>
      {providerConfig.name === 'indosat' &&
        <div style={{ fontWeight: 'bold' }}><span onClick={() => setSelectedLang('ID')} style={{ color: selectLang === 'ID' ? '#d93b4f' : headerFontColor, cursor: 'pointer' }}>ID</span><span style={{ margin: '0px 5px' }}>|</span><span onClick={() => setSelectedLang('EN')} style={{ color: selectLang === 'EN' ? '#d93b4f' : headerFontColor, cursor: 'pointer' }}>EN</span></div>
      }
      <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <div style={{ cursor: "pointer", textWrap: 'nowrap', color: welcomeTextColor, display: 'flex', alignItems: 'center' }}>
          Welcome,<b>{user}</b>
        </div>
      </Dropdown>
    </div>
  );


  const welcome = isMobile ? welcomeMobile : welcomeDesktop;

  return (
    <div className="Header" style={{
      ...containerStyle,
      position: 'relative',
      zIndex: 4,
      backgroundColor: providerConfig.status ? (providerConfig.name == 'business-dashboard' ? '#FED80C' : 'transparent') : 'transparent',
      paddingBottom: providerConfig.status ? (providerConfig.name == 'business-dashboard' ? '24px' : '0px') : '0px',
    }}>
      <SignUpModalV2 />
      <LoginModalV2 />
      <div className="innercontainer">
        <div style={{
          marginRight: "auto",
        }}>
          <Link to={providerConfig.status ? `/${providerConfig.name}` : '/'}>
            {providerConfig.status ? <div className={`logo-${providerConfig.name} ${logoColor ? logoColor : ''}`} /> : <div className="logo" />}
          </Link>
        </div>

        <div style={{
          display: window.location.pathname == '/indosat' ? 'block' : 'none'
        }}>
          <Button type="primary" style={{ borderRadius: '24px', marginRight: '20px' }} href="/business">Business</Button>
        </div>

        <div style={{
          marginTop: providerConfig.status ? (providerConfig.name == 'business-dashboard' ? '24px' : '0px') : '0px',
        }}>
          {user ? welcome : signIn}
        </div>
      </div>
    </div>
  );
};

export default Header;
