import React, { useEffect, useState } from 'react'
import ModalV2 from 'components/Global/ModalV2/ModalV2'
import { b2bIndosatPromotePICModalVisibleState } from "recoil/atoms"
import { Button, message, Select } from 'antd'
import { styles } from './PromotePICModal.styles.js'
import Axios from 'axios'
import AxiosRequestHelper from 'helpers/AxiosRequestHelper.js'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

const PromotePICModal = () => {
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)
    const isVisiblePromotePICModal = useRecoilValue(b2bIndosatPromotePICModalVisibleState)

    const { Option } = Select;

    const onChange = (value) => {
        setSelectedOption(value)
        // console.log(`selected ${value}`);
    }

    const onBlur = () => {
        console.log('blur');
    }

    const onFocus = () => {
        console.log('focus');
    }

    const onSearch = (val) => {
        // setData(prevData =>
        //     prevData.filter(member =>
        //         member.User.firstName.toLowerCase().includes(val.toLowerCase()) ||
        //         member.User.lastName.toLowerCase().includes(val.toLowerCase())
        //     )
        // );
    }

    const logout = (event) => {
        localStorage.removeItem("usertoken");
        localStorage.removeItem("userdata");
        localStorage.removeItem("useremail");
        localStorage.removeItem("is-test-acc");
    
        const currentPath = location.pathname;
        if (currentPath === '/business-dashboard') window.location.href = '/business'
        else window.location.href = '/'
      };

    const onClickConfirm = () => {
        const explode = selectedOption.split('|')

        const url = `/company/${company.id}/team-members/${explode[0]}`

        const payload = {
            status: 'pic',
        };

        AxiosRequestHelper('post', url, payload)
            .then((res) => {
                message.success("PIC has been promoted");

                setTimeout(() => {
                    logout()
                }, 2000);
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message || "An error occurred";
                message.error(errorMessage);
            });
        // console.log(selectedOption)
    }

    const company = JSON.parse(localStorage.getItem("companyData"));
    const getApprovedTeamMembers = async () => {
        setIsLoading(true)

        let config = {
            method: "get",
            url: `/company/${company.id}/team-members`,
            params: {
                status: 'approved'
            }
        };

        const res = await Axios(config)
        if (res.status === 200) {
            console.log(res.data)
            setData(res.data);
            setIsLoading(false)
        }

    }

    useEffect(() => {
        getApprovedTeamMembers()
    }, [isVisiblePromotePICModal])


    return (
        <ModalV2
            recoilState={b2bIndosatPromotePICModalVisibleState}
            children={
                <div>
                    <h2>Are you sure you would like to switch PIC?</h2>

                    {/* <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a team member to be promoted to a PIC"
                        optionFilterProp="children"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            (!isLoading && data?.length > 0) && data.forEach(member => {
                                return <Option value={`${member.id}|${member.User.id}`}>{member.User.firstName} {member.User.lastName}</Option>
                            })
                        }
                    </Select> */}

                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a team member to be promoted to a PIC"
                        optionFilterProp="children"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        // filterOption={(input, option) =>
                        //     option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                        filterOption={(input, option) => option.props.children.join('').includes(input.toLowerCase())
                        }
                    >
                        {
                            (!isLoading && data?.length > 0) && data.map(member => (
                                <Option key={`${member.id}|${member.User.id}`} value={`${member.id}|${member.User.id}`}>
                                    {member.User.firstName} {member.User.lastName}
                                </Option>
                            ))
                        }
                    </Select>

                    <p style={{ marginTop: '16px' }}>Promoting a new PIC will execute the following actions:</p>
                    <ul>
                        <li style={styles.list}>You will be logged out once you click confirm since only the new PIC will be able to access this dashboard</li>
                    </ul>

                    <div style={styles.actionList}>
                        <Button
                            type="secondary"
                            htmlType="submit"
                            // onClick={handleSubmit}
                            style={{}}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={onClickConfirm}
                            style={{}}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            }
        />

    )
}

export default PromotePICModal