import { Button, Form, Input, message } from 'antd';
import React from 'react'
import useSecretResetPasswordFormLogic from './SecretResetPasswordForm.logic';
import useSecretResetPasswordStyles from './SecretResetPassword.styles'

const SecretResetPasswordFormComponent = (props) => {
    const { form } = props;
    const { styles } = useSecretResetPasswordStyles();

    const {
        isLoadingSubmit,
        onClickSubmit,
        getButtonActionTitle,
        isButtonDisabled
    } = useSecretResetPasswordFormLogic({ form });

    const validateToken = (rule, value, callback) => {
        if (!value) {
            callback('Please enter the token.');
        } else if (value !== 'thisIsASecretToken') {
            callback('Invalid token.');
        } else {
            callback();
        }
    }

    return (
        <Form
            layout="vertical"
            onSubmit={onClickSubmit}
            style={styles.form}
        >
            {/* Email */}
            <Form.Item>
                {form.getFieldDecorator("email", {
                    rules: [
                        { required: true, message: 'Please enter your email.' },
                        { type: 'email', message: 'Please enter a valid email.' }
                    ]
                })(
                    <Input placeholder="Email" style={styles.input} />
                )}
            </Form.Item>

            {/* Token */}
            <Form.Item>
                {form.getFieldDecorator("token", {
                    rules: [
                        { required: true, message: 'Please enter the token.' },
                        { validator: validateToken }
                    ]
                })(
                    <Input placeholder="Token" style={styles.input} />
                )}
            </Form.Item>

            {/* New Password */}
            <Form.Item>
                {form.getFieldDecorator("newPassword", {
                    rules: [
                        { required: true, message: 'Please enter your new password.' },
                        { min: 8, message: 'Password must be at least 8 characters.' }
                    ]
                })(
                    <Input.Password placeholder="New Password" style={styles.input} />
                )}
            </Form.Item>

            {/* Submit Button */}
            <Form.Item>
                <Button
                    type="primary"
                    onClick={onClickSubmit}
                    loading={isLoadingSubmit}
                    disabled={isButtonDisabled()}
                >
                    {getButtonActionTitle()}
                </Button>
            </Form.Item>
        </Form>
    );
}

const SecretResetPasswordForm = Form.create({ name: "secret_reset_form" })(SecretResetPasswordFormComponent);

export default SecretResetPasswordForm;
